import React, { useState, useEffect } from 'react';
import { contentfulClient } from '../../Utils';

export default function SeoKeyword(props) {
  const [siteMapData, setSiteMapData] = useState([]);

  const getSiteMapData = async () => {
    const siteMap = await contentfulClient.getEntries({
      content_type: 'siteMap',
      include: 7,
      'fields.name': 'SiteMapKeywords',
    });

    if (siteMap?.items?.length > 0) {
      setSiteMapData(siteMap?.items);
    }
  };

  useEffect(() => {
    if (props?.defaultVal === 'categoryPage') {
      setSiteMapData(props?.categorySiteMap);
    } else {
      getSiteMapData();
    }
  }, []);

  return (
    <div className={`${props?.defaultVal === 'categoryPage' ? 'px-3 md:px-4' : 'container mx-auto px-3'}`}>
      {siteMapData?.length > 0 ? (
        <h2 className="font-bold opacity-80 text-18 md:text-28">
          <span>{siteMapData[0]?.fields?.bigTitle} : </span> {siteMapData[0]?.fields?.smallTitle}
        </h2>
      ) : null}

      {siteMapData?.length > 0 ? (
        <div className="pb-3 text-12 md:text-14">
          {siteMapData[0]?.fields?.siteKeywordsLinks?.map((item, index) => (
            <div key={item?.fields?.categorySubTitle}>
              {item?.fields?.categorySubTitle ? <span> {item?.fields?.categorySubTitle} : </span> : null}

              <span>
                {item?.fields?.categoryKeyLink?.map((keywordLinks, index) => (
                  <React.Fragment key={`${keywordLinks?.fields?.textHyperLink}_${index}`}>
                    <a className="inline-block " href={keywordLinks?.fields?.textHyperLink}>
                      {keywordLinks?.fields?.name}
                    </a>{' '}
                    |{' '}
                  </React.Fragment>
                ))}
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
