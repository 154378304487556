import {
  GAEvent_bannerViewPromotion,
  GAEvent_categoryBannerViewPromotion,
} from '@/redux/actions/GAEventsActions/globalEvents/GAbannerPromotion';
import { debouncer, getContentfulClientEntries } from '@/src/Utils';
import HOC from '@/src/components/Hoc/hoc';
import SeoKeyword from '@/src/components/SeoKeyword/SeoKeyword';
import DetectVisible from '@/src/components/Tagmanager/DetectVisible';
import GlobalLayout from '@/src/layouts/global';
import * as wcmsOptimization from '@/src/wcmsOptimization';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import React, { Component } from 'react';
import { InView } from 'react-intersection-observer';
import { connect } from 'react-redux';

import { getCookie, setCookie } from '@/src/Utils/Cookies';
import CategoryCarousel from '@/src/components/Floors/CategoryCarousel/CategoryCarousel';
import {
  AppLink,
  BlogBanner,
  CategoryDescription,
  CategoryListing,
  DualBannerCard,
  FourBanner,
  HeadingMainTitle,
  HeroBanner,
  HeroBannerV2,
  MainBanner,
  MainBannerV2,
  MiniBanner,
  MultipleBigBanner,
  Persoreco,
  ProductCarouselTitle,
  ProductCollage,
  ProductRecall,
  ProductScroll,
  ShopByCategory,
  ShopByCategoryScroll,
  ShopByCategoryScrollRect,
  getCallMainbannerV1,
  getCallMainbannerV2,
} from '@/src/components/Floors/CmsFloors/CmsFloors';
import PromiseFloorV2 from '@/src/components/Floors/PromiseTabs/PromiseFloorV2';
import setDYContext from '@/src/lib/dynamicYield/setDYContext';
import { indexPageContent } from '../constant/contentfulConstants';
import CategoryBanners from '../src/components/Floors/CategoryBanners/CategoryBanners';
import { removeCookie } from '../src/Utils/Cookies';
import DyFloor from '../src/components/Floors/DynamicYieldFloors/DyFloor';

/* GTM INIT */
let heroBannerPromoData = [];
let mainBannerPromoData = [];
let miniBannerPromoData = [];
let multiBannerPromodata = [];
let dualBannerPromodata = [];
let productBannerPromoData = [];
let blogBannerPromoData = [];
let fourBannerPromoData = [];
let headinMainPromoData = [];
let productScrollBannerPromoData = [];
let categoryDescriptionPromoData = [];
let promoViewsent = false;
/* GTM INIT END */

const floorDisplayTypes = {
  webOnly: 'webOnly',
  webAndApp: 'webAndApp',
};

const { floorTypes } = wcmsOptimization;
class Index extends Component {
  static async getInitialProps(ctx) {
    if (ctx?.isServer) {
      ctx.res.setHeader('Cache-Control', 'public,max-age=1800'); // in seconds - 1800 s = 30 mins
    }

    const homepageContent = await getContentfulClientEntries(
      {
        content_type: indexPageContent.type,
        include: 7,
        [indexPageContent.key]: indexPageContent.field,
      },
      ctx.res,
    );
    if (!homepageContent) {
      return {};
    }
    const entries = {
      metaTitle: homepageContent.items[0].fields.metaTitle,
      metaDescription: homepageContent.items[0].fields.metaDescription,
      floors: homepageContent.items[0].fields.floor.map((floor) => {
        let floorType = floor?.sys?.contentType?.sys?.id || '';

        switch (floorType) {
          case floorTypes.categoryCarousel:
            return wcmsOptimization.categoryCarousel(floor);

          case floorTypes.promiseSection:
            return wcmsOptimization.promiseSection(floor);

          case floorTypes.mainBanner:
            return wcmsOptimization.mainBanner(floor);

          case floorTypes.miniBanner:
            return wcmsOptimization.miniBanner(floor);

          case floorTypes.fourBanner:
            return wcmsOptimization.fourBanner(floor);

          case floorTypes.heroBanner:
            return wcmsOptimization.heroBanner(floor);

          case floorTypes.productCollage:
            return wcmsOptimization.productCollage(floor);

          case floorTypes.categoryDescription:
            return wcmsOptimization.categoryDescription(floor);

          case floorTypes.productCarousel:
            return wcmsOptimization.productCarousel(floor);

          case floorTypes.blogBanner:
            return wcmsOptimization.blogBanner(floor);

          case floorTypes.multipleBanner:
            return wcmsOptimization.multipleBanner(floor);

          case floorTypes.dualBannerCard:
            return wcmsOptimization.dualBannerCard(floor);

          case floorTypes.list:
            return wcmsOptimization.list(floor);

          case floorTypes.persoReco:
            return wcmsOptimization.persoReco(floor);

          case floorTypes.productScroll:
            return wcmsOptimization.productScroll(floor);

          case floorTypes.headingMainTitle:
            return wcmsOptimization.headingMainTitle(floor);

          case floorTypes.shopByCategory:
            return wcmsOptimization.shopByCategories(floor);

          case floorTypes.shopByCategoryScroll:
            return wcmsOptimization.shopByCategoryScroll(floor);

          case floorTypes.shopByCategoryScrollRect:
            return wcmsOptimization.shopByCategoryScrollRect(floor);

          case floorTypes.categoryBanners:
            return wcmsOptimization.CategoryBannersMapper(floor);

          case floorTypes.content:
            return wcmsOptimization.content(floor);

          default:
            return floor;
        }
      }),
    };

    return {
      entries,
    };
  }

  async componentDidMount() {
    window.addEventListener('scroll', () => {
      debouncer(this.handleScroll, 500); // Scroll Impressions
    });
    const userSession = getCookie('dkt_location');
    const scrollPosition = parseInt(window.sessionStorage.getItem('homeScrollPosition'), 10);

    if (scrollPosition) {
      window.scrollTo(0, scrollPosition - 200);
      window.sessionStorage.removeItem('homeScrollPosition');
    }

    if (userSession == null || userSession === '' || typeof userSession === 'undefined') {
      setCookie('dkt_location', 'location');
    }

    setDYContext({ type: 'HOMEPAGE' });
  }

  handleScroll() {
    DetectVisible();
  }

  componentWillUnmount() {
    window.sessionStorage.setItem('homeScrollPosition', window.scrollY);
    window.removeEventListener('scroll', this.handleScroll);
  }

  // All Banners Data
  mainBannerCallbackFun = (mainData) => {
    mainBannerPromoData = mainData;
    this.triggerPromoView(mainData);
  };

  miniBannerCallbackFun = (miniData) => {
    miniBannerPromoData = miniData;
    this.triggerPromoView(miniData);
  };

  heroBannerCallbackFun = (heroData) => {
    heroBannerPromoData = heroData;
    this.triggerPromoView(heroData);
  };

  multiBannerCallbackFun = (multiData) => {
    multiBannerPromodata = multiData;
    this.triggerPromoView(multiData);
  };

  categoryDescriptionCallbackFun = (categoryDescData) => {
    categoryDescriptionPromoData = categoryDescData;
    this.triggerPromoView(categoryDescData);
  };

  // For DualBanner

  dualBannerCallbackFun = (dualData) => {
    dualBannerPromodata = dualData;
    this.triggerPromoView(dualData);
  };

  blogBannerCallbackFun = (blogData) => {
    blogBannerPromoData = blogData;
    this.triggerPromoView(blogData);
  };

  headinMainCallbackFun = (HeadingTitleData) => {
    headinMainPromoData = HeadingTitleData;
    this.triggerPromoView(HeadingTitleData);
  };

  fourBannerCallbackFun = (fourBannerData) => {
    fourBannerPromoData = fourBannerData;
    this.triggerPromoView(fourBannerData);
  };

  productBannerCallbackFun = (productData) => {
    productBannerPromoData = productData;
    this.triggerPromoView(productData);
  };

  productScrollBannerCallbackFun = (productData) => {
    productScrollBannerPromoData = productData;
    this.triggerPromoView(productData);
  };

  categoryBannersCallbackFun = (bannerData) => {
    if (window?.location?.pathname === '/' && window?.location?.href?.indexOf('query') === -1) {
      GAEvent_categoryBannerViewPromotion(bannerData);
    }
  };

  triggerPromoView = (bannerData) => {
    if (window?.location?.pathname === '/' && window?.location?.href?.indexOf('query') === -1) {
      GAEvent_bannerViewPromotion(bannerData);
    }
  };

  wcmsFloors = (entries, device) => {
    const isLoggedIn = getCookie('dkt_isLoggedIn');
    const ArrayData = [];
    let firstMainbannerFloor = true;
    const placeholder = <div style={{ minHeight: '25vh' }} />;
    const isDesktop = typeof window !== 'undefined' && window.innerWidth > 765;

    entries?.floors?.forEach((floor, index) => {
      let data = '';

      const paytmMiniDevice =
        device === 'PAYTM_MINI' && floor.excludeShopInShop && typeof floor.excludeShopInShop !== 'undefined';
      const webFloors =
        floor.displayType === floorDisplayTypes.webOnly || floor.displayType === floorDisplayTypes.webAndApp;

      switch (true) {
        case floor.type === floorTypes.categoryCarousel && webFloors && !paytmMiniDevice:
          data = (
            <div className={`floor-${index}`}>
              <CategoryCarousel data={floor.data} index={index} />
            </div>
          );
          break;

        // New design promise floor
        case floor.type === floorTypes.promiseSection && webFloors && !paytmMiniDevice:
          data = (
            <div className={`floor-${index}`}>
              <PromiseFloorV2 cards={floor.cards} />
            </div>
          );
          break;

        //DY Floor
        case floor.contentType === 'DY Floor':
          data = (
            <div className={`floor-${index}`}>
              <DyFloor data={floor.data} pageType="HOMEPAGE" />
            </div>
          );
          break;

        // New design Heading Main Title floor
        case floor.type === floorTypes.headingMainTitle && webFloors:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <HeadingMainTitle
                      data={floor.data}
                      mainTitle={floor.mainTitle}
                      headinMainCallback={this.headinMainCallbackFun}
                    />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        // shop by category floor
        case floor.type === floorTypes.shopByCategory && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? <ShopByCategory items={floor.items} /> : placeholder}
                </div>
              )}
            </InView>
          );
          break;

        // shop by scrollable category floor
        case floor.type === floorTypes.shopByCategoryScroll && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? <ShopByCategoryScroll items={floor.items} /> : placeholder}
                </div>
              )}
            </InView>
          );
          break;

        // shop by scrollable category floor Rectangular
        case floor.type === floorTypes.shopByCategoryScrollRect && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? <ShopByCategoryScrollRect items={floor.items} /> : placeholder}
                </div>
              )}
            </InView>
          );
          break;

        // New  Main Banner with Version
        case floor.type === floorTypes.mainBanner && webFloors && !paytmMiniDevice:
          const mainBanners = floor.data.banners.filter((banner) => banner.floorType !== 'top_three');
          if (floor.version === '2') {
            if (firstMainbannerFloor) {
              firstMainbannerFloor = false;
              const Banners = getCallMainbannerV2(mainBanners);

              data = (
                <div className={`floor-${index}`}>
                  <Banners
                    key={index}
                    data={mainBanners}
                    mainBannerCallback={this.mainBannerCallbackFun}
                    pincode={this.props.pincode}
                  />
                </div>
              );
            } else {
              data = (
                <InView triggerOnce key={index}>
                  {({ inView, ref }) => (
                    <div className={`floor-${index}`} ref={ref}>
                      {inView ? (
                        <MainBannerV2
                          key={this.props.pincode + index + isLoggedIn}
                          data={floor.data.banners}
                          mainBannerCallback={this.mainBannerCallbackFun}
                          pincode={this.props.pincode}
                        />
                      ) : (
                        placeholder
                      )}
                    </div>
                  )}
                </InView>
              );
            }
          } else {
            if (firstMainbannerFloor) {
              firstMainbannerFloor = false;
              const BannersV1 = getCallMainbannerV1(mainBanners);
              data = (
                <div className={`floor-${index}`}>
                  <BannersV1
                    key={this.props.pincode + index + this.props.isLoggedIn}
                    data={floor.data}
                    mainBannerCallback={this.mainBannerCallbackFun}
                    pincode={this.props.pincode}
                  />
                </div>
              );
            } else {
              data = (
                <InView triggerOnce key={index}>
                  {({ inView, ref }) => (
                    <div className={`floor-${index}`} ref={ref}>
                      {inView ? (
                        <MainBanner
                          key={this.props.pincode + index + this.props.isLoggedIn}
                          data={floor.data}
                          mainBannerCallback={this.mainBannerCallbackFun}
                          pincode={this.props.pincode}
                        />
                      ) : (
                        placeholder
                      )}
                    </div>
                  )}
                </InView>
              );
            }
          }
          break;

        case floor.type === floorTypes.miniBanner && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <MiniBanner
                      data={floor.data}
                      miniBannerCallback={this.miniBannerCallbackFun}
                      floorHeading={floor.floorHeading}
                    />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        // New Floor - Four Banner
        case floor.type === floorTypes.fourBanner && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <FourBanner data={floor.data} fourBannerCallback={this.fourBannerCallbackFun} />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        // New Hero banner floor with version=2
        case floor.type === floorTypes.heroBanner && webFloors:
          if (floor.version === '2') {
            data = (
              <InView triggerOnce key={index}>
                {({ inView, ref }) => (
                  <div className={`container px-2 mx-auto mb-8 md:px-6 xl:px-0 floor-${index}`} ref={ref}>
                    {inView ? (
                      <HeroBannerV2
                        data={floor.data}
                        heroBannerCallback={this.heroBannerCallbackFun}
                        floorHeading={floor.heading}
                      />
                    ) : (
                      placeholder
                    )}
                  </div>
                )}
              </InView>
            );
          } else {
            data = (
              <InView triggerOnce key={index}>
                {({ inView, ref }) => (
                  <div className={`floor-${index}`} ref={ref}>
                    {inView ? (
                      <HeroBanner
                        data={floor.data}
                        heroBannerCallback={this.heroBannerCallbackFun}
                        floorHeading={floor.heading}
                      />
                    ) : (
                      placeholder
                    )}
                  </div>
                )}
              </InView>
            );
          }
          break;

        case floor.type === floorTypes.productCollage && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <ProductCollage data={floor.data} productBannerCallback={this.productBannerCallbackFun} />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        case floor.type === floorTypes.productCarousel && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`container mx-auto mb-8 md:px-6 xl:px-0 floor-${index}`} ref={ref}>
                  {inView ? <ProductCarouselTitle data={floor.data} /> : placeholder}
                </div>
              )}
            </InView>
          );
          break;

        // New banner for Blogs
        case floor.type === floorTypes.blogBanner && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`container px-2 mx-auto mb-8 md:px-6 xl:px-2 floor-${index}`} ref={ref}>
                  {inView ? (
                    <BlogBanner data={floor.data} blogBannerCallback={this.blogBannerCallbackFun} />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        case floor.type === floorTypes.multipleBanner && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <MultipleBigBanner data={floor.data} multiBannerCallback={this.multiBannerCallbackFun} />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        // For Dual Banner
        case floor.type === floorTypes.dualBannerCard && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <DualBannerCard data={floor.data} dualBannerCallback={this.dualBannerCallbackFun} />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        case floor.type === floorTypes.categoryDescription && webFloors:
          data = (
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <div className="container mx-auto">
                      <CategoryDescription
                        description={floor.data.description}
                        categoryDescriptionCallback={this.categoryDescriptionCallbackFun}
                      />
                    </div>
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        case floor?.sys?.contentType?.sys?.id === 'productRecall' &&
          (floor.fields.display === 'webOnly' || floor.fields.display === 'webAndApp'):
          if (
            device === 'PAYTM_MINI' &&
            floor.fields.excludeShopInShop &&
            typeof floor.fields.excludeShopInShop !== 'undefined'
          )
            break;

          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? <ProductRecall data={floor} /> : placeholder}
                </div>
              )}
            </InView>
          );

          break;

        case floor.type === floorTypes.list && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? <CategoryListing data={floor.data} /> : placeholder}
                </div>
              )}
            </InView>
          );

          break;

        case floor.type === floorTypes.persoReco && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <Persoreco
                      key={this.props.pincode + index + isLoggedIn}
                      data={floor.data}
                      pincode={this.props.pincode}
                    />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        case floor.type === floorTypes.productScroll && webFloors && !paytmMiniDevice:
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? (
                    <ProductScroll
                      data={floor.data}
                      productScrollBannerCallback={this.productScrollBannerCallbackFun}
                    />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        // category banner elements
        case floor.type === 'categoryBannerfloor' &&
          ((floor.data[0]?.web && isDesktop) || (floor.data[0]?.mWeb && !isDesktop)):
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`w-full my-3 floor-${index}`} ref={ref}>
                  {inView ? (
                    <CategoryBanners
                      data={floor.data}
                      bannerIndex={isDesktop ? floor.data[0].floorPositionWeb : floor.data[0].floorPosition}
                      isDesktop={isDesktop}
                      homePage={true}
                      categoryBannersCallback={this.categoryBannersCallbackFun}
                    />
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </InView>
          );
          break;

        case floor?.sys?.contentType?.sys?.id === 'appFloor':
          data = (
            <InView triggerOnce key={index}>
              {({ inView, ref }) => (
                <div className={`floor-${index}`} ref={ref}>
                  {inView ? <AppLink data={floor} /> : placeholder}
                </div>
              )}
            </InView>
          );
          break;

        default:
          data = <div className={`floor-${index}`}></div>;

          break;
      }

      ArrayData.push(data);
    });
    return ArrayData;
  };

  render() {
    const { entries, device } = this.props;
    return (
      <div className="bg-white">
        <HOC>
          <Head>
            <title>{entries?.metaTitle}</title>
          </Head>
          <NextSeo
            title={entries?.metaTitle}
            description={entries?.metaDescription}
            openGraph={{
              url: process.env.NEXT_PUBLIC_SITE_URL,
              title: entries?.metaTitle,
              description: entries?.metaDescription,
            }}
            nofollow={process.env.NEXT_PUBLIC_ENVIRONMENT !== 'PRODUCTION'}
            noindex={process.env.NEXT_PUBLIC_ENVIRONMENT !== 'PRODUCTION'}
          />

          <h1 className="sr-only">Decathlon: Shop from 50 Sports and 5000 Products In-Store & Online</h1>
          <div className="home-floors">{this.wcmsFloors(entries, device)}</div>
          <SeoKeyword />
        </HOC>
      </div>
    );
  }
}

Index.getLayout = function getLayout(page) {
  return <GlobalLayout>{page}</GlobalLayout>;
};

const mapStateToProps = (state) => ({
  device: state.reducer.device,
  pincode: state.reducer.pincode,
});

export default connect(mapStateToProps, null)(Index);
